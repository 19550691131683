import Config from '@/models/Config';
import Pagination from '@/models/Pagination';
import BaseService from './BaseService';

class ConfigService extends BaseService {
    constructor() {
        super();
    }

    getConfig(id) {
        return this.get(`configs/${id}`).then(
            (response) => new Config(response.data)
        );
    }

    createConfig(data) {
        return this.post(`configs`, data).then(
            (response) => new Config(response.data)
        );
    }

    updateConfig(id, data) {
        return this.patch(`configs/${id}`, data);
    }

    deleteConfig(id) {
        return this.delete(`configs/${id}`);
    }

    getConfigs(pagination, sorting) {
        let p = pagination;
        let s = sorting;
        return this.get(
            `configs?page=${p.page}&per_page=${p.pageSize}&sort=${s.sort}`
        )
            .then((response) => response.data)
            .then((response) => {
                let configs = response.results.map((c) => new Config(c));
                let pagination = new Pagination(response);

                return { configs, pagination };
            });
    }

    getPublicConfig(id) {
        return this.get(`public/configs/${id}`).then(
            (response) => new Config(response.data)
        );
    }

    getPublicConfigsBySearch(keyword, pagination, sorting) {
        let p = pagination;
        let s = sorting;
        return this.get(
            `public/configs/search?keyword=${keyword}&page=${p.page}&per_page=${p.pageSize}&sort=${s.sort}`
        )
            .then((response) => response.data)
            .then((response) => {
                let configs = response.results.map((c) => new Config(c));
                let pagination = new Pagination(response);

                return { configs, pagination };
            });
    }
}

export default new ConfigService();